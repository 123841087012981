<template>
  <el-dialog v-if="show" :visible.sync="show" :title="form.id?'编辑':'新增'" width="504px" class="dialog-vertical"
    @close="resetForm('ruleForm')">
    <div class="content">
      <el-form v-if='form' :model="form" :rules="rules" ref="ruleForm" label-width="100px" size="medium"
        class="small-form">
        <!-- 角色名 -->
        <el-form-item label="等级头衔：" prop="header_street">
          <el-input v-focus v-model="form.header_street" placeholder="请输入等级头衔"></el-input>
        </el-form-item>
        <el-form-item label="等级：" prop="level">
          <el-input type="number" v-model.number="form.level" placeholder="请输入等级"></el-input>
        </el-form-item>
        <el-form-item label="经验值下限：" prop="experience_value">
          <el-input type="number" v-model.number="form.experience_value" placeholder="请输入经验值下限"></el-input>
        </el-form-item>
        <el-form-item label="图标：" prop="icon">
          <DefaultImageSelector v-model="form.icon" :DefaultImage="DefaultImage" :width="80" :height="80"
            :borderRadius="4" />
        </el-form-item>
        <el-form-item label="启用：" prop="is_open">
          <el-radio v-model="form.is_open" :label="1">启用</el-radio>
          <el-radio v-model="form.is_open" :label="0">不启用</el-radio>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="show=false">取消</el-button>
      <el-button size="small" type="primary" :loading="loading" @click="oncConfirm('ruleForm')">确认</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { saveLevel } from "../api/income-level-config";
// import { roleSave } from "../../api/role";
import DefaultImageSelector from "../../common/components/DefaultImageSelector.vue";

export default {
  components: { DefaultImageSelector },
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    //是否显示
    open: Boolean,
    //完成后的回调
    complete: Function,
    //新增编辑时使用的数据
    data: Object,
    default: () => {
      return {
        id: 0,
        header_street: "",
        level: "",
        experience_value: "",
        icon:
          window.serverConfig.VUE_APP_ADMINURL +
          "/incomes/images/admin/lv_8.png",
        is_open: 1,
      };
    },
  },
  data() {
    return {
      loading: false, //保存中
      DefaultImage:
        window.serverConfig.VUE_APP_ADMINURL +
        "/incomes/images/admin/lv_8.png",
      form: null,
      rules: {
        header_street: [
          { required: true, message: "请输入等级头衔", trigger: "blur" },
        ],
        level: [
          { required: true, message: "请输入等级", trigger: "blur" },
          { type: "integer", min: 0, message: "请输入正整数", trigger: "blur" },
        ],
        experience_value: [
          { required: true, message: "请输入经验值下限", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
  },
  methods: {
    //确认
    oncConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          //提交的数据
          const postData = { ...this.form };
          //请求
          saveLevel(postData)
            .then((res) => {
              //运行回调
              if (this.complete) this.complete();
              //关闭
              this.loading = false;
              this.show = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    //获取系统平台列表
    // this.getSystemsList();
    //获取系统平台可用角色列表
    // this.getAvailableList();
  },
  watch: {
    //编辑模式
    show(val) {
      if (val) {
        this.form = { ...this.data };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-vertical {
  .content {
    color: #303133;
    .small-form {
      .el-form-item--medium:last-child {
        margin-bottom: 0;
      }
    }
    .authorize-select {
      & + .authorize-select {
        margin-top: 10px;
      }
    }
    .delete-select {
      margin-left: 5px;
      padding: 2px;
    }
    .add-authorize {
      margin-top: 10px;
    }
  }
}
</style>

import api from "@/base/utils/request";

// 获取等级列表数据
export const levelList = data => {
  return api({
    url: "/admin/incomes/level_config/index",
    method: "post",
    data
  });
};

// 是否开启
export const levelIsOpen = data => {
  return api({
    url: "/admin/incomes/level_config/isOpen",
    method: "post",
    data
  });
};

//新增/编辑等级
export const saveLevel = data => {
  return api({
    url: "/admin/incomes/level_config/save",
    method: "post",
    data
  });
};

// 删除
export const levelDel = data => {
  return api({
    url: "/admin/incomes/level_config/del",
    method: "post",
    data
  });
};
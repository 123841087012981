<template>
  <div class="list-page">
    <el-button size="small" class="top-btn" type="primary" @click="onAddEdit('add')">添加</el-button>
    <div class="list-content" v-loading="tableLoading">
      <el-table :data="tbody" style="width: 100%" class="thead-light" stripe>
        <el-table-column show-overflow-tooltip v-for="th in thead" :key="th.prop" :label="th.label"
          :prop="th.prop" :width="th.width|| ''" :min-width="th.minWidth || ''" :fixed="th.fixed">
          <template slot-scope="{row}">
            <!-- 操作 -->
            <template v-if="th.prop==='operate'">
              <el-button size="small" type="text" @click="onAddEdit('edit',row)">编辑</el-button>
              <el-button size="small" type="text" @click="onDelete(row.id)">删除</el-button>
            </template>
            <!-- 图标 -->
            <el-image class="list-icon" v-else-if="th.prop === 'icon'" :src="row[th.prop]"
              @click="$previewImage([row[th.prop]])" fit="cover"></el-image>
            <!-- 启用状态 -->
            <el-checkbox v-else-if="th.prop === 'is_open'" :true-label="1" :false-label="0"
              v-model="row[th.prop]" @change="(status)=> onDisableEnable(row.id, status)">
            </el-checkbox>
            <!-- 其他 -->
            <span v-else>{{row[th.prop] | placeholder}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 创建和编辑弹窗 -->
    <add-edit-dialog v-model="openAddEditDialog" :complete="getLevelList" :data="editData" />
  </div>
</template>
<script>
import { levelList, levelIsOpen, levelDel } from "../api/income-level-config";
import AddEditDialog from "../components/AddEditDialog";
export default {
  data() {
    return {
      tableLoading: false, //表格加载中
      thead: [
        { label: "等级", prop: "level", minWidth: 60 },
        { label: "图标", prop: "icon", minWidth: 60 },
        { label: "等级头衔", prop: "header_street", minWidth: 110 },
        { label: "经验值下限", prop: "experience_value", minWidth: 120 },
        { label: "启用", prop: "is_open", minWidth: 100 },
        { label: "操作", prop: "operate", minWidth: 100, fixed: "right" },
      ],
      tbody: [],
      current_id: 0,
      openAddEditDialog: false, //显示新建&编辑弹窗
      editData: null, //编辑弹窗的数据
      deleteId: "", //删除的角色id
      openDeleteDialog: false, //是否开启删除弹窗
    };
  },
  methods: {
    //获取数据列表
    getLevelList() {
      this.tableLoading = true;
      levelList()
        .then((res) => {
          const { data } = res;
          this.tbody = data;
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    //新增或者编辑
    onAddEdit(type, data) {
      switch (type) {
        case "add":
          this.editData = {
            id: 0,
            header_street: "",
            level: "",
            experience_value: "",
            icon:
              window.serverConfig.VUE_APP_ADMINURL +
              "/incomes/images/admin/lv_8.png",
            is_open: 1,
          };
          break;
        case "edit":
          this.editData = data;
          break;
      }
      this.openAddEditDialog = true;
    },
    //更新状态
    onDisableEnable(id, status) {
      this.tableLoading = true;
      levelIsOpen({ id, is_open: status })
        .then((res) => {
          this.$message.success(res.msg);
          this.getLevelList();
        })
        .catch((err) => {
          this.getLevelList();
        });
    },
    //删除
    onDelete(id) {
      this.$msgbox
        .confirm("确定要删除此数据吗？", "提示", {
          type: "error",
        })
        .then((res) => {
          this.tableLoading = true;
          levelDel({ id })
            .then((res) => {
              this.$message.success(res.msg);
              this.getLevelList();
            })
            .catch((err) => {
              this.tableLoading = false;
            });
        })
        .catch((err) => {});
    },
  },
  created() {
    //获取数据列表
    this.getLevelList();
  },
  components: {
    AddEditDialog,
  },
};
</script>

<style lang="scss" scoped>
.list-icon {
  height: 32px;
  width: 32px;
  cursor: pointer;
  // border-radius: 50%;
}
</style>
